import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./modules/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Founders from "./pages/Founders";
import Awards from "./pages/Awards";
import Csr from "./pages/Csr";
import Contact from "./pages/Contact";
import Ongoing from "./pages/Ongoing";
import Astor from "./pages/Astor";
import Adora from "./pages/Adora";
import Altura from "./pages/Altura";
import CloverCounty from "./pages/CloverCounty";
import Arista from "./pages/Arista";
import Completed from "./pages/Completed";
import Upcoming from "./pages/Upcoming";
import Nopage from "./pages/Nopage";
import CustomerSpeaks from "./pages/CustomerSpeaks"; 
import Commercial from "./pages/Commercial";
import BlogListing from "./pages/BlogListing"; 
import BuyersGuide from "./pages/BuyersGuide";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Media from "./pages/Media";
import Services from "./pages/Services";
import Footer from "./modules/Footer";
import Careers from "./pages/Careers";
import BlogDetails from "./pages/BlogDetails";
import Blog1 from "./pages/Blog1";
import Blog2 from "./pages/Blog2";

export default function App() {

  return (
    <BrowserRouter>
      <Header />
      <div className="mkd-wrapper">
        <div className="mkd-content">
          <div className="mkd-content-inner">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/founders" element={<Founders />} />
              <Route path="/awards" element={<Awards />} />
              <Route path="/csr" element={<Csr />} />
              <Route path="/ongoing" element={<Ongoing />} />
              <Route path="/astor" element={<Astor />} />
              <Route path="/adora" element={<Adora />} />
              <Route path="/altura" element={<Altura />} />
              <Route path="/clover-county" element={<CloverCounty />} />
              <Route path="/arista" element={<Arista />} />
              <Route path="/completed" element={<Completed />} />
              <Route path="/upcoming" element={<Upcoming />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/customer-speaks" element={<CustomerSpeaks />} />
              <Route path="/blog-listing" element={<BlogListing />} />
              <Route path="/commercial" element={<Commercial />} />
              <Route path="/buyers-guide" element={<BuyersGuide />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/media" element={<Media />} />
              <Route path="/services" element={<Services />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/blog-details" element={<BlogDetails />} />
              <Route path="/blog1" element={<Blog1 />} />
              <Route path="/blog2" element={<Blog2 />} />
              <Route path="*" element={<Nopage />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </div>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);