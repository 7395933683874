const Footer = () => {
  return (
    <>
      <footer className="mkd-page-footer mkd-footer-uncover mkd-footer-has-footer-top mkd-footer-has-footer-bottom">
        <div className="mkd-footer-top-holder">
          <div className="mkd-footer-top-inner mkd-grid mkd-footer-top-has-logo">
            <div className="mkd-footer-logo-area">
              <div className="mkd-footer-logo-wrapper"></div>
              <div className="mkd-footer-line" />
            </div>
            <div className="mkd-grid-row mkd-footer-top-alignment-left">
              <div className="mkd-column-content mkd-grid-col-3">
                <div className="mkd-footer-column-inner">
                  <div id="text-4" className="widget mkd-footer-column-3 widget_text">
                    <div className="mkd-widget-title-holder">
                      <h6 className="mkd-widget-title">Projects</h6>
                    </div>
                    <div className="textwidget">
                      <p>
                        <a href="https://www.newry.co.in/ongoing">
                          Ongoing Projects
                        </a>
                      </p>
                      <p>
                        <a href="https://www.newry.co.in/completed">
                          Completed Projects
                        </a>
                      </p>
                      <p>
                        <a href="https://www.newry.co.in/upcoming">
                          Upcoming Projects
                        </a>
                      </p>
                      <p>
                        <a href="https://www.newry.co.in/commercial">
                          Commercial Projects
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mkd-column-content mkd-grid-col-3">
                <div className="mkd-footer-column-inner">
                  <div id="text-2" className="widget mkd-footer-column-1 widget_text">
                    <div className="mkd-widget-title-holder">
                      <h6 className="mkd-widget-title">Quick Links</h6>
                    </div>
                    <div className="textwidget">
                      <p>
                        <a href="https://www.newry.co.in/about">
                          About Us
                        </a>
                      </p>
                      <p>
                        <a href="https://www.newry.co.in/customer-speaks">
                          Customer Speak
                        </a>
                      </p>
                      <p>
                        <a href="https://www.newry.co.in/services">
                          Services
                        </a>
                      </p>
                      <p>
                        <a href="https://www.newry.co.in/buyers-guide">
                          Buyer's Guide
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mkd-column-content mkd-grid-col-3">
                <div className="mkd-footer-column-inner">
                  <div id="text-3" className="widget mkd-footer-column-2 widget_text">
                    <div className="mkd-widget-title-holder">
                      <h6 className="mkd-widget-title">Quick Links</h6>
                    </div>
                    <div className="textwidget">
                      <p>
                        <a href="https://www.newry.co.in/careers">
                          Careers
                        </a>
                      </p>
                      <p>
                        <a href="https://www.newry.co.in/contact">
                          Contact
                        </a>
                      </p>
                      <p>
                        <a href="https://www.newry.co.in/blog-details">
                          Blogs
                        </a>
                      </p>
                      <p>
                        <a href="https://www.newry.co.in/media">
                          Media
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mkd-column-content mkd-grid-col-3">
                <div className="mkd-footer-column-inner">
                  <div className="widget mkd-social-icons-group-widget text-align-left">
                    <div className="mkd-widget-title-holder">
                      <h6 className="mkd-widget-title">Follow us</h6>
                    </div>
                    <a
                      className="mkd-social-icons-group-widget-holder mkd-icon-has-hover"
                      style={{ fontSize: 18, margin: "0px 29px 0px 0px" }}
                      href="https://www.facebook.com/NewryProperties"
                      target="_blank"
                    >
                      <span className="mkd-social-icon-widget fa fa-facebook" />
                    </a>
                    <a
                      className="mkd-social-icons-group-widget-holder mkd-icon-has-hover"
                      style={{ fontSize: 18, margin: "0px 29px 0px 0px" }}
                      href="https://www.instagram.com/newrypropertiesprivatelimited/"
                      target="_blank"
                    >
                      <span className="mkd-social-icon-widget fa fa-instagram" />
                    </a>
                    <a
                      className="mkd-social-icons-group-widget-holder mkd-icon-has-hover"
                      style={{ fontSize: 18, margin: "0px 29px 0px 0px" }}
                      href="https://x.com/newryproperties"
                      target="_blank"
                    >
                      <img src="assets/twitter-icon.svg" style={{ height: 18 }} />
                    </a>
                    <a
                      className="mkd-social-icons-group-widget-holder mkd-icon-has-hover"
                      style={{ fontSize: 18, margin: "0px 29px 0px 0px" }}
                      href="https://www.youtube.com/channel/UColnNNXqwwWIPzoWVFGOKkQ"
                      target="_blank"
                    >
                      <span className="mkd-social-icon-widget fa fa-youtube" />
                    </a>
                    <a
                      className="mkd-social-icons-group-widget-holder mkd-icon-has-hover"
                      style={{ fontSize: 18, margin: "0px 29px 0px 0px" }}
                      href="https://in.linkedin.com/company/newrypropertiesprivatelimited"
                      target="_blank"
                    >
                      <span className="mkd-social-icon-widget fa fa-linkedin" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mkd-footer-line" />
          </div>
        </div>
        <div className="mkd-footer-bottom-holder">
          <div className="mkd-footer-bottom-inner mkd-grid">
            <div className="mkd-grid-row ">
              <div className="mkd-grid-col-6">
                <div
                  id="text-6"
                  className="widget mkd-footer-bottom-column-1 widget_text"
                >
                  <div className="textwidget">
                    <p>

                      <a target="_blank" rel="noopener">
                        © 2025 - Newry Properties. All rights reserved.
                      </a>
                      <a href="https://www.newry.co.in/privacy-policy">

                        Privacy Policy &nbsp;
                      </a>
                      | &nbsp;
                      <a href="https://www.newry.co.in/terms-and-conditions">

                        Terms and Conditions
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="mkd-grid-col-6"></div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
};

export default Footer;